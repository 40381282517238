import i18n from '@/language/i18n'
import router from '@/router'
// Import {useCookies} from '@vueuse/integrations/useCookies'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'

const loadingStore = useLoading()
// Const cookies = useCookies()

const useContact = defineStore('useContact', {
  state : () => {
    return {
      locale          : 'ja',
      mode            : 'ChangeInfo',

      contactData         : {},
      content             : null,
      editFlag            : true,
      completedFlag       : false,
      memberId            : null,
      selectedCountry     : {},
      confirmEmail        : null,
      exportFiles         : [],
      constants           : [],
      filesName           : [],
      errors              : {},
      // Rule check button clicked
      ruleCheckBtnClicked         : false,
    }
  },
  getters : {
  },
  actions : {
    reset() {
      this.contactData = {}
      this.content = null
      this.editFlag = true
      this.completedFlag = false
      this.memberId = null
      this.selectedCountry = {}
      this.confirmEmail = null
      this.exportFiles = []
      this.constants = []
      this.filesName = []
      this.errors = {}
      // Rule check button clicked
      this.ruleCheckBtnClicked = false
    },
    request(params) {
      return Methods.apiExcute('public/request-inquiry', this.locale, params)
    },
    getConstants(cookies) {
      loadingStore.setLoading(true)
      Methods.apiExcute('public/get-contact-constants', this.locale).then(data => {
        loadingStore.setLoading(false)
        this.constants = data.constants
        this.memberId = data.memberId
        if (data.member) {
          this.contactData = Object.assign({}, data.member, this.contactData)
        }
      })
        .catch(error => {
          loadingStore.setLoading(false)
          Methods.parseHtmlResponseError(router, error)
        })
    },
    searchPostCode() {
      console.log('searchPostCode')
      const params = {
        postCode : this.contactData.postCode
      }
      loadingStore.setLoading(true)
      Methods.apiExcute('search-post-code', this.locale, params).then(data => {
        loadingStore.setLoading(false)
        this.contactData.address = (data.address1 || '') + (data.address2 || '') + (data.address3 || '')
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    validate($refs) {
      console.log('validate')
      if (this.ruleCheckBtnClicked === false) {
        return
      }
      const params = {
        inquiryData  : this.contactData,
        content      : this.content,
        validateFlag : true
      }
      this.errors = {}
      this.checkInputFormat()
      loadingStore.setLoading(true)

      console.log('params = ', params)
      this.request(params)
        .then(() => {
          if (Object.keys(this.errors).length === 0) {
            loadingStore.setLoading(false)
            this.editFlag = false
            window.scrollTo({
              top      : 0,
              left     : 0,
              behavior : 'smooth'
            })
          } else {
            loadingStore.setLoading(false)
          }
        })
        .catch(error => {
          console.log('error', error)
          loadingStore.setLoading(false)
          // 表示順のため
          let default_errs = {
            nickname         : null,
            nickname_dup     : null,
            name             : null,
            furigana         : null,
            birthday         : null,
            postCode         : null,
            address          : null,
            tel              : null,
            email            : null,
            email_dup        : null,
            confirmEmail     : null,
            password         : null,
            confirmPassword  : null
          }
          default_errs = Object.assign(default_errs, this.errors, Methods.parseHtmlResponseError(router, error))
          const tmp_errs = {}
          Object.keys(default_errs).map(x => {
            if (default_errs[x]) {
              tmp_errs[x] = default_errs[x]
            }
          })
          this.errors = Object.assign({}, tmp_errs)

          // Move pointer to first error item
          this.moveToError($refs)
        })
    },
    checkInputFormat() {
      console.log('checkInputFormat')
      let tags = [
        'nickname',
        'name',
        'furigana',
      ]

      if (this.mode === 'RegisterMember') {
        tags = [
          'nickname',
          'name',
          'furigana',
          'address',
          'email'
        ]
      }

      const pattern = new RegExp('^[0-9a-zA-Z -/:-@\[-~]+$')
      const jpPattern = new RegExp('^([^\x01-\x7E]|[0-9a-zA-Z -/:-@\[-~])+$')
      this.errors = {}
      for (const tag of tags) {
        if (this.contactData[tag]) {
          if (!jpPattern.test(this.contactData[tag])) {
            this.errors[tag] = i18n.t('MEMBER_REQUEST_TEXT_FORMAT_ERROR')
          }
        }
      }
    },
    ruleCheckClick() {
      // 更新ボタンの活性・非活性
      if (document.getElementById('rule-chk').checked === false) {
        console.log('uncheck')
        this.ruleCheckBtnClicked = false
      } else {
        console.log('checked')
        this.ruleCheckBtnClicked = true
      }
    },
    moveToError($refs) {
      const keys = Object.keys(this.errors)
      if (keys.length > 0) {
        let refkey = keys[0]
        if (keys[0] === 'email_dup') {
          refkey = 'email'
        } else if (keys[0] === 'nickname_dup') {
          refkey = 'nickname'
        }
        if (this.errors[keys[0]] && $refs[refkey]) {
          if (refkey === 'birthday') $refs[refkey].scrollIntoView()
          else $refs[refkey].focus()
        }
      }
    }
  },
})

export default useContact
