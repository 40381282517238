<template>
  <main id="main" class="contact-used">
    <div class="wrap1160">
      <ContactItemInfo :parent="$data" :getItem="getItem" :favorite="favorite"/>
      <ContactForm v-if="editFlag"/>
      <Completion v-else-if="completedFlag"/>
      <ComfirmForm v-else/>
    </div>
  </main>
</template>

<script>
import Methods from '@/api/methods'
import useContact from '@/stores/useContact'
import {defineComponent} from '@vue/composition-api'
import ComfirmForm from '../../components/Contact/Comfirm'
import Completion from '../../components/Contact/Completion'
import ContactForm from '../../components/Contact/Form'
import ContactItemInfo from '../../components/Contact/ItemInfo'
export default defineComponent({
  setup() {
    const contactStore = useContact()
    return {
      contactStore
    }
  },
  components : {
    ContactForm,
    ComfirmForm,
    Completion,
    ContactItemInfo
  },
  data() {
    return {
      typeTag         : 'AUCTION_PAGE_SEARCH_TYPE',
    }
  },
  computed : {
    editFlag() {
      return this.contactStore.editFlag
    },
    completedFlag() {
      return this.contactStore.completedFlag
    },
  },
  methods : {
    getItem(params) {
      return Methods.apiExcute('public/get-item-info', this.$i18n.locale, params)
    },
    favorite(params) {
      return Methods.apiExcute('private/favorite-item', this.$i18n.locale, params)
    }
  }
})
</script>

  <style scoped lang="scss" src="@/assets/scss/style.scss"></style>
