<template>
<section id="target-item">
  <ul>
    <li>
      <div class="target-item-img">
        <span class="target-item-img-wrap com-item-box">
          <img class="item-box-image" v-if="item.image && item.image.length > 0" :src="item.image[0].postar_file_path ?  '/' + encodeURIComponent(item.image[0].postar_file_path) :  '/' + encodeURIComponent(item.image[0].file_path)" alt="">
          <img class="item-box-image" v-else src="@/assets/img/no_photo.jpg" alt="">
          <span v-if="item.attention_info" v-bind:class="{'fav-mark': true, 'active': item.attention_info.is_favorited}" @click="favoriteItem(item.attention_info.is_favorited);item.attention_info.is_favorited=!item.attention_info.is_favorited"></span>
        </span>
      </div>
      <div class="target-item-txt">
        <div class="header-info">
          <p class="target-item-no" v-if="item.lot_id">{{item.lot_id}}</p>
          <p class="stock-location" v-if="item.area_id">{{getAreaName(item.area_id)}}</p>
          <p class="stock-location" v-if="!item.area_id && item.free_field && item.free_field.SYBSNM">{{item.free_field.SYBSNM}}</p>
          <p class="target-item-system">{{$t(parent.typeTag)}}</p>
        </div>
        <div class="target-item-data">
          <div class="detail-block">
            <dl v-if="item.free_field.MAKER">
              <dt>{{$t("ITEM_ROW_MAKER_LABEL")}}</dt>
              <dd>{{item.free_field.MAKER}}</dd>
            </dl>
            <dl>
              <dt>{{$t("ITEM_ROW_MDLGR_LABEL")}}</dt>
              <dd>{{item.free_field.MODEL}}</dd>
            </dl>
            <dl>
              <dt>{{$t("ITEM_ROW_ACTSERNO_LABEL")}}</dt>
              <dd>{{item.free_field.ACTSERNO}}</dd>
            </dl>
            <dl>
              <dt>{{$t("ITEM_ROW_YYYY_LABEL")}}</dt>
              <dd>{{item.free_field.YYYY ? item.free_field.YYYY + $t("ITEM_ROW_YYYY_UNIT_LABEL") : $t("ITEM_ROW_YYYY_NOT_CLEAR_LABEL")}}</dd>
            </dl>
            <dl>
              <dt>{{$t("ITEM_ROW_OPETIM_LABEL")}}</dt>
              <dd>{{number2string(item.free_field.OPETIM, $t("ITEM_ROW_OPETIM_NOT_CLEAR_LABEL"), 'hr')}}</dd>
            </dl>
          </div>
          <div class="detail-block">
            <dl>
              <dt>{{$t("DETAIL_MILEAGE_LABEL")}}</dt>
              <dd>{{number2string(item.free_field.MILEAGE, '', 'km')}}</dd>
            </dl>
            <dl>
              <dt>{{$t("CONTACT_FORM_CAB_LABEL")}}</dt>
              <dd>{{item.free_field.CAB}}</dd>
            </dl>
            <dl>
              <dt>{{$t("CONTACT_FORM_UDRCARR_LABEL")}}</dt>
              <dd>{{item.free_field.UDRCARR}}</dd>
            </dl>
            <dl>
              <dt>{{$t("DETAIL_SPECIFICATION_LABEL")}}</dt>
              <dd>{{item.free_field.OTHERSY}}</dd>
            </dl>
            <dl>
              <dt>{{$t("DETAIL_CR_INSPECTION_LABEL")}}</dt>
              <dd>{{item.free_field.DAYCRAN}}</dd>
            </dl>
          </div>
          <div class="detail-block">
            <dl>
              <dt>{{$t("DETAIL_OUTRIGGER_LABEL")}}</dt>
              <dd>{{item.free_field.OUTRIGR}}</dd>
            </dl>
            <dl>
              <dt>{{$t("DETAIL_HOOK_LABEL")}}</dt>
              <dd>{{item.free_field.HOOK}}</dd>
            </dl>
            <dl>
              <dt>{{$t("DETAIL_VEHICLE_INSPECTION_LABEL")}}</dt>
              <dd>{{item.free_field.DAYCARI}}</dd>
            </dl>
            <dl>
              <dt>{{$t("DETAIL_MAXIMUM_RATED_TOTAL_LOAD_LABEL")}}</dt>
              <dd>{{number2string(item.free_field.ABIL, '', '')}}</dd>
            </dl>
          </div>
        </div>
      </div>
    </li>
  </ul>
</section>
</template>

<script>
import Methods from '@/api/methods'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import RowBid from '../ItemList/mixins/RowBid.js'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    return {
      loadingStore
    }
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    },
    getItem : {
      type    : Function,
      default : Object
    },
    favorite : {
      type    : Function,
      default : Object
    }
  },
  mixins : [RowBid],
  data() {
    return {
      errors    : {},
      constants : [],
      item      : {
        free_field : {}
      }
    }
  },
  created() {
    this.getConstants()
      .then(() => {
        this.getItemInfo()
      })
      .catch(err => {
        this.loadingStore.setLoading(false)
        Methods.parseHtmlResponseError(this.$router, err)
      })
  },
  methods : {
    getItemInfo() {
      this.loadingStore.setLoading(true)
      const params = {
        exhibitionItemNo : this.$route.params.id
      }
      this.getItem(params)
        .then(data => {
          this.loadingStore.setLoading(false)
          this.item = data
        })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    getConstants() {
      this.loadingStore.setLoading(true)
      return Methods.apiExcute('public/get-item-search-constants', this.$i18n.locale)
        .then(data => {
          this.loadingStore.setLoading(false)
          this.constants = []
          return Promise.resolve()
        })
    },
    getAreaName(areaId) {
      if (this.constants) {
        const filtered_areas = this.constants.filter(x => x.value1 === areaId)
        return filtered_areas.length > 0 ? filtered_areas[0].value2 : ''
      }
      return ''
    }
  },
  watch : {
    '$i18n.locale'() {
      this.getConstants()
        .then(() => {
          console.log('get constants')
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
})
</script>

<style scoped lang="scss">
  #main.contact-used #target-item ul li .target-item-txt p.target-item-system {
    height: 32px;
    line-height: 28px;
  }
  #main.contact-used #target-item ul li .target-item-txt p.target-item-no {
    height: 32px;
  }
  #main.contact-used #target-item ul li .target-item-txt .header-info {
    width: 100%;
    display: flex;
  }
  #main.contact-used #target-item ul li .target-item-txt {
    padding: 5px 0 15px 25px;
  }
  /* ---------------------------
  スペック
  ----------------------------- */
  #main.contact-used #target-item ul li .target-item-txt .target-item-data {
    width: 100%;
  }
  #main.contact-used #target-item ul li .target-item-txt .target-item-data .detail-block {
    display: block;
    min-width: calc(100% / 3);
    padding-right: 15px;
  }
  #main.contact-used #target-item ul li .target-item-txt .target-item-data .detail-block dl {
    width: 100%;
    padding-right: 15px;
  }
  #main.contact-used #target-item ul li .target-item-txt .target-item-data .detail-block dl dd {
    line-break: anywhere;
  }

  @media only screen and (max-width: 767px) {
    #main.contact-used #target-item ul li .target-item-txt .target-item-data .detail-block{
      width: 100%;
      min-width: 0;
      margin-right: 0;
      border-bottom: 1px dashed #EAEAEA;
    }
    #main.contact-used #target-item ul li .target-item-txt .target-item-data .detail-block dl dt {
      width: 130px;
      min-width: 0;
    }
    #main.contact-used #target-item ul li .target-item-txt p.target-item-system {
      margin-top: 0;
      text-align: center;
      padding: 2px 10px;
    }
    #main.contact-used #target-item ul li .target-item-txt .header-info {
      width: 100%;
      display: flex;
      margin-top: 10px;
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
