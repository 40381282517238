<template>
<section id="entry-form" class="bgLGray">
  <form>
    <p class="entry-form-info" v-html="$t('MEMBER_REQUEST_CONFIRM_EXPLANATION_MESSAGE')"></p>
    <table class="tbl-entry">
      <tbody>
        <tr>
          <th>ID</th>
          <td>
            {{contactStore.memberId ? contactStore.memberId : $t('CONTACT_FORM_GUEST_TEXT')}}
          </td>
        </tr>
        <tr>
          <th v-html="$t('MEMBER_REQUEST_FORM_NICKNAME_LABEL')"></th>
          <td>{{contactData.nickname}}</td>
        </tr>
        <tr>
          <th v-html="$t('MEMBER_REQUEST_FORM_NAME_LABEL')"></th>
          <td>
            {{contactData.name}}
          </td>
        </tr>
        <tr>
          <th v-html="$t('MEMBER_REQUEST_FORM_FURIGANA_LABEL')"></th>
          <td>
            {{contactData.furigana}}
          </td>
        </tr>
        <tr>
          <th v-html="$t('MEMBER_REQUEST_FORM_BIRTHDAY_LABEL')"></th>
          <td>
            {{ contactData.birthday | dateFormat }}
          </td>
        </tr>
        <tr>
          <th v-html="$t('MEMBER_REQUEST_CONFIRM_POST_CODE_LABEL')"></th>
          <td>
            {{contactData.postCode}}
          </td>
        </tr>
        <tr>
          <th v-html="$t('MEMBER_REQUEST_FORM_ADDRESS_LABEL')"></th>
          <td>
            {{contactData.address}}
          </td>
        </tr>
        <tr>
          <th>
            {{$t('MEMBER_REQUEST_FORM_TEL_LABEL')}}<br />
          </th>
          <td>
            {{contactData.tel || ''}}
          </td>
        </tr>
        <tr>
          <th v-html="$t('CONTACT_EXPORT_FILE_LABEL')"></th>
          <td>
            <div v-for="file in contactStore.exportFiles" :key="file.name">{{file.name}}</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="btn-form">
      <input type="button" class="btn-back" :value="$t('MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL')" @click="parent.editFlag=true"><input type="button" :value="$t('MEMBER_REQUEST_CONFIRM_SEND_BUTTON_LABEL')" @click="requestInquiry">
    </div>
  </form>
</section>
</template>

<script>
import Methods from '@/api/methods'
import CommonFilters from '@/common/commonFilters'
import useContact from '@/stores/useContact'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const contactStore = useContact()
    return {
      loadingStore,
      contactStore
    }
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  mixins   : [CommonFilters],
  data() {
    return {
      errors : {}
    }
  },
  computed : {
    contactData() {
      return this.contactStore.contactData
    },
  },
  methods : {
    requestInquiry() {
      const params = {
        inquiryData      : this.contactData,
        exhibitionItemNo : this.$route.name === this.$define.PAGE_NAMES.ITEM_CONTACT_PAGE_NAME ? this.$route.params.id : null,
        itemNo           : this.$route.name === this.$define.PAGE_NAMES.STOCK_CONTACT_PAGE_NAME ? this.$route.params.id : null,
        content          : this.contactStore.content,
        files            : this.contactStore.exportFiles.map(file => file.key)
      }
      this.loadingStore.setLoading(true)
      Methods.apiExcute('public/request-inquiry', this.$i18n.locale, params).then(data => {
        this.loadingStore.setLoading(false)
        this.contactStore.completedFlag = true
        window.scrollTo({
          top      : 0,
          left     : 0,
          behavior : 'smooth'
        })
      })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.errors = Object.assign(Methods.parseHtmlResponseError(this.$router, error), this.errors)
        })
    }
  }
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
