<template>
<section id="entry-form" class="bgLGray">
  <form>
    <p class="entry-form-info" v-html="$t('CONTACT_FORM_INFO_TEXT')"></p>
    <table class="tbl-entry">
      <tbody>
        <tr>
          <th>ID</th>
          <td>
            {{contactStore.memberId ? contactStore.memberId : $t('CONTACT_FORM_GUEST_TEXT')}}
          </td>
        </tr>
        <tr>
          <th>
            {{$t('MEMBER_REQUEST_FORM_NICKNAME_LABEL')}}
            <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
          </th>
          <td>
            <input type="text" class="iptW-M" required v-model="contactData.nickname" v-bind:class="{'err': errors.nickname || errors.nickname_dup}" @keydown="errors.nickname=null" ref="nickname">
            <p v-if="errors.nickname" class="err-txt">{{errors.nickname}}</p>
            <p v-if="errors.nickname_dup" class="err-txt">{{errors.nickname_dup}}</p>
          </td>
        </tr>
        <tr>
          <th>
            {{$t('MEMBER_REQUEST_FORM_NAME_LABEL')}}
            <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
          </th>
          <td>
            <input type="text" class="iptW-M" required v-model="contactData.name" v-bind:class="{'err': errors.name}" @keydown="errors.name=null" ref="name">
            <p class="err-txt">{{errors.name}}</p>
          </td>
        </tr>
        <tr>
          <th>
            {{$t('MEMBER_REQUEST_FORM_FURIGANA_LABEL')}}
            <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
          </th>
          <td>
            <input type="text" class="iptW-M" required v-model="contactData.furigana" v-bind:class="{'err': errors.furigana}" @keydown="errors.furigana=null" ref="furigana">
            <p class="err-txt">{{errors.furigana}}</p>
          </td>
        </tr>
        <tr>
          <th>
            {{$t('MEMBER_REQUEST_FORM_BIRTHDAY_LABEL')}}
            <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
          </th>
          <td>
            <date-picker
              class="iptW-M"
              :modelValue="contactData.birthday"
              @update:modelValue="newValue => {errors.birthday = null; contactData.birthday = newValue}"
              v-bind:class="{'date-err': errors.birthday}"
              @input="errors.birthday = null"
              :errors="errors"
              refTxt="birthday"/>
            <p class="err-txt">{{errors.birthday}}</p>
          </td>
        </tr>
        <tr class="account-wrap">
          <th>
            {{$t('MEMBER_REQUEST_FORM_POST_CODE_LABEL')}}
            <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
          </th>
          <td>
            <div class="ipt-wrap">
              <input
                type="text"
                class="ime-dis iptW-S"
                v-model="contactData.postCode"
                @keydown="errors.postCode=null"
                required v-bind:class="{'err': errors.postCode}"
                ref="postCode" />
              <input class="ipt-btn-gray zip-search" type="button" :value="$t('MEMBER_REQUEST_FORM_SEARCH_POST_CODE_BUTTON_LABEL')" @click="contactStore.searchPostCode">
              <p class="err-txt">{{errors.postCode}}</p>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            {{$t('MEMBER_EDIT_FORM_ADDRESS_LABEL')}}
            <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
          </th>
          <td>
            <input type="text" class="iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_LOCATION_PLACEHOLDER')" required v-model="contactData.address" v-bind:class="{'err': errors.address}" @keydown="errors.address=null" ref="address">
            <p class="err-txt">{{errors.address}}</p>
          </td>
        </tr>
        <tr>
          <th>
            {{$t('MEMBER_REQUEST_FORM_TEL_LABEL')}}<br />
            <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
          </th>
          <td>
            <input type="text" class="ime-dis iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_PHONE_NUMBER_PLACEHOLDER')" required v-model="contactData.tel" v-bind:class="{'err': errors.tel}" @keydown="errors.tel=null" ref="tel">
            <p class="err-txt">{{errors.tel}}</p>
          </td>
        </tr>

        <tr class="account-wrap">
          <th>
            {{$t('MEMBER_REQUEST_FORM_EMAIL_LABEL')}}<br />
            <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
          </th>
          <td>
            <input type="email" class="ime-dis iptW-M" required v-model="contactData.email" v-bind:class="{'err': errors.email || errors.email_dup}" @keydown="errors.email=null" ref="email">
            <p v-if="errors.email" class="err-txt">{{errors.email}}</p>
            <p v-if="errors.email_dup" class="err-txt">{{errors.email_dup}}</p>
          </td>
        </tr>
        <tr class="account-wrap">
          <th>
            {{$t('MEMBER_REQUEST_FORM_CONFIRM_EMAIL_LABEL')}}
            <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
          </th>
          <td>
            <input type="email" class="ime-dis iptW-M" required v-model="contactStore.confirmEmail" v-bind:class="{'err': errors.confirmEmail}" @keydown="errors.confirmEmail=null" ref="confirmEmail">
            <p v-if="errors.confirmEmail" class="err-txt">{{errors.confirmEmail}}</p>
          </td>
        </tr>

        <tr>
          <th>{{$t('CONTACT_FORM_CONTACT_CONTENT_LABEL')}}<em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em></th>
          <td>
            <textarea v-model="contactStore.content" v-bind:class="{'textarea-content': true, 'err': errors.content}" @keydown="errors.content=null" ></textarea>
            <p class="err-txt">{{errors.content}}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="form-privacy">
      {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1')}}
      <a class="link-text" @click="$routerGoNewWindow($define.PAGE_NAMES.PRIVACY_PAGE_NAME, null, {lang: $i18n.locale})">{{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2')}}</a>
      {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3')}}
    </p>
    <table class="tbl-entry tbl-privacy">
      <tr>
        <th>
          {{$t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL') + $t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL2')}}
          <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
        </th>
        <td class="privacy-chk">
          <label for="rule-chk">
            <input type="checkbox" id="rule-chk" class="checkbox-input" @click="contactStore.ruleCheckClick" required>
            <span class="checkbox-parts" v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></span>
          </label>
        </td>
      </tr>
    </table>
    <div class="btn-form">
      <input type="button" :value="$t('MEMBER_REQUEST_FORM_SUBMIT_LABEL')" :disabled="!contactStore.ruleCheckBtnClicked" @click="contactStore.validate($refs)">
    </div>
  </form>
</section>
</template>

<script>
import UploadFile from '@/api/uploadFile'
import DatePicker from '@/components/RegisterMember/DatePicker'
import useContact from '@/stores/useContact'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const contactStore = useContact()
    return {
      loadingStore,
      contactStore
    }
  },
  components : {
    DatePicker
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  data() {
    return {
    }
  },
  computed : {
    contactData() {
      return this.contactStore.contactData
    },
    errors() {
      return this.contactStore.errors
    }
  },
  methods : {
    uploadFiles() {
      if (this.contactStore.exportFiles.filter(file => !file.key).length === 0) {
        return Promise.resolve()
      }
      return Promise.resolve()
        .then(() => {
          return UploadFile.getUploadCredentials('inquiry')
        })
        .then(credentials => {
          return Promise.all(this.contactStore.exportFiles.filter(file => !file.key).map(file => {
            return UploadFile.upload(credentials, file)
              .then(result => {
                file.key = result.Key
                return Promise.resolve(result.Key)
              })
          }))
        })
    }
  },
  mounted() {
  },
  created() {
    this.contactStore.reset()
    this.contactStore.getConstants()
  },
  watch : {
    '$i18n.locale'() {
      this.contactStore.getConstants()
    },
    errors(value) {
      const keys = Object.keys(value)
      if (keys.length > 0 && this.$refs[keys[0]]) {
        this.$refs[keys[0]].focus()
      }
    }
  }
})
</script>
<style scoped lang="scss">
  .ipt-file-box {
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 768px) {
    p.err-txt {
      position: absolute;
      top: 50%;
      left: 450px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      max-width: 290px;
    }
    .textarea-content {
      background-color: white;
      width:430px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    p.err-txt {
        position: static;
        -webkit-transform: none;
        transform: none;
        max-width: 100%;
        margin-top: 5px;
    }
  }

  .with-int-no {
    width: 305px !important;
  }
  select.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  label.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  .bold-text {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
