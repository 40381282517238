import { render, staticRenderFns } from "./Comfirm.vue?vue&type=template&id=553ffd60&scoped=true&"
import script from "./Comfirm.vue?vue&type=script&lang=js&"
export * from "./Comfirm.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/style.scss?vue&type=style&index=0&id=553ffd60&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "553ffd60",
  null
  
)

export default component.exports